// src/components/FriendSearch/FriendSearch.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  getDoc,
  setDoc,
  arrayUnion,
  onSnapshot
} from 'firebase/firestore';
import DefaultAvatar from '../DefaultAvatar';
import FriendRequests from '../FriendRequests/FriendRequests';
import './FriendSearch.css';

const FriendSearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [pendingRequests, setPendingRequests] = useState(new Set());
  const [error, setError] = useState('');

  // Listen to real-time updates for the current user's document
  useEffect(() => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const userDocRef = doc(db, 'users', currentUser.uid);
    
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        setPendingRequests(new Set(userData.sentFriendRequests || []));
      }
    }, (error) => {
      console.error("Error listening to user updates:", error);
      setError(t('friends.error_realtime_updates'));
    });

    return () => unsubscribe();
  }, [t]);

  const handleSearch = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setError('');
    
    if (!value.trim()) {
      setSearchResults([]);
      return;
    }

    setIsLoading(true);
    try {
      // Create queries for different search criteria
      const queries = [
        // Search by username (case-insensitive)
        query(collection(db, "users"), 
          where("username", ">=", value.toLowerCase()),
          where("username", "<=", value.toLowerCase() + '\uf8ff')
        ),
        // Search by display name
        query(collection(db, "users"), 
          where("displayName", ">=", value),
          where("displayName", "<=", value + '\uf8ff')
        ),
        // Search by phone number
        query(collection(db, "users"), 
          where("phoneNumber", ">=", value),
          where("phoneNumber", "<=", value + '\uf8ff')
        )
      ];

      const querySnapshots = await Promise.all(queries.map(q => getDocs(q)));
      const userMap = new Map();
      
      // Combine and deduplicate results
      for (const snapshot of querySnapshots) {
        snapshot.docs.forEach(doc => {
          if (doc.id !== auth.currentUser?.uid) { // Exclude current user
            userMap.set(doc.id, { id: doc.id, ...doc.data() });
          }
        });
      }

      // Get current user's friends list to exclude existing friends
      const currentUserDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      const currentUserData = currentUserDoc.data();
      const existingFriends = new Set(currentUserData.friends || []);

      const filteredResults = Array.from(userMap.values())
        .filter(user => !existingFriends.has(user.id));

      // Calculate mutual friends
      const resultsWithMutual = await Promise.all(
        filteredResults.map(async (user) => {
          const userFriends = new Set(user.friends || []);
          const mutualFriends = [...existingFriends].filter(id => userFriends.has(id)).length;
          return { ...user, mutualFriends };
        })
      );

      setSearchResults(resultsWithMutual);
    } catch (error) {
      console.error("Search error:", error);
      setError(t('friends.error_search_failed'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendRequest = async (e, userId) => {
    e.stopPropagation(); // Prevent navigation when clicking the button
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      await setDoc(doc(db, 'users', userId), {
        friendRequests: arrayUnion(currentUser.uid)
      }, { merge: true });

      await setDoc(doc(db, 'users', currentUser.uid), {
        sentFriendRequests: arrayUnion(userId)
      }, { merge: true });
    } catch (error) {
      console.error("Error sending friend request:", error);
      setError(t('friends.error_send_request'));
    }
  };

  const handleUserClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const getDisplayName = (user) => {
    if (user.displayName) return user.displayName;
    if (user.username) return user.username;
    return t('friends.anonymous_friend');
  };

  return (
    <div className="friend-search-container">
      <FriendRequests />
      
      <div className="friend-search-section">
        <div className="friend-search-wrapper">
          <input
            type="text"
            placeholder={t('friends.search_placeholder')}
            value={searchTerm}
            onChange={handleSearch}
            className="friend-search-input"
          />
          {searchTerm && (
            <button
              onClick={() => {
                setSearchTerm('');
                setSearchResults([]);
              }}
              className="friend-search-clear"
            >
              ✕
            </button>
          )}
        </div>

        {error && <div className="friend-search-error">{error}</div>}

        <div className="friend-search-results">
          {isLoading ? (
            <div className="friend-search-loading">{t('friends.searching')}</div>
          ) : (
            searchResults.map((user) => (
              <div 
                key={user.id} 
                className="friend-search-card"
                onClick={() => handleUserClick(user.id)}
                role="button"
                tabIndex="0"
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleUserClick(user.id);
                  }
                }}
              >
                <div className="friend-search-user-info">
                  <div className="friend-search-avatar">
                    {user.profilePictureUrl ? (
                      <img
                        src={user.profilePictureUrl}
                        alt={t('friends.profile_picture_alt', { name: getDisplayName(user) })}
                        className="friend-search-avatar-image"
                        loading="lazy"
                      />
                    ) : (
                      <DefaultAvatar
                        name={getDisplayName(user)}
                        size={48}
                        fallbackColor="var(--color-primary-light)"
                        textColor="var(--color-surface)"
                      />
                    )}
                  </div>
                  <div className="friend-search-details">
                    <h3>{getDisplayName(user)}</h3>
                    {user.username && (
                      <p className="friend-search-username">@{user.username}</p>
                    )}
                    {user.mutualFriends > 0 && (
                      <p className="friend-search-mutual">
                        {user.mutualFriends} mutual friend{user.mutualFriends !== 1 ? 's' : ''}
                      </p>
                    )}
                  </div>
                </div>
                <button
                  className={`friend-search-add-btn ${pendingRequests.has(user.id) ? 'requested' : ''}`}
                  disabled={pendingRequests.has(user.id)}
                  onClick={(e) => handleSendRequest(e, user.id)}
                >
                  {pendingRequests.has(user.id) ? t('friends.requested') : t('friends.add_friend')}
                </button>
              </div>
            ))
          )}
          
          {searchTerm && !isLoading && searchResults.length === 0 && (
            <div className="friend-search-no-results">
              {t('friends.no_results', { searchTerm })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FriendSearch;
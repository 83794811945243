// src/components/GroupHeader.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  ChevronLeft,
  Users,
  Bot,
  BotOff,
  ClipboardList
} from 'lucide-react';
import './GroupHeader.css';

const GroupHeader = ({ 
  group, 
  onBack,
  onOpenSettings,
  onToggleBotResponses,
  botResponsesEnabled = true,
  onShowAllPosts
}) => {
  const { t } = useTranslation();

  return (
    <header className="group-header">
      <div className="group-header-container">
        <div className="group-header-left">
          <button
            onClick={onBack}
            className="back-button"
            aria-label={t('common.back')}
          >
            <ChevronLeft size={30} />
          </button>

          <button 
            onClick={onOpenSettings}
            className="group-info"
            aria-label={t('groups.view_group_info')}
          >          
            {group.icon ? (
              <img 
                src={group.icon} 
                alt={group.name}
                className="group-icon"
              />
            ) : (
              <div className="group-icon-placeholder">
                {group.name.charAt(0).toUpperCase()}
              </div>
            )}
            
            <div className="group-details">
              <h2 className="group-name">{group.name}</h2>
              <div className="group-meta">
                <Users size={16} />
                <span>{t('groups.member_count', { count: group.memberCount })}</span>
              </div>
            </div>
          </button>
        </div>

        <div className="group-header-right">
          <button
            onClick={onShowAllPosts}
            className="all-posts-button"
            aria-label={t('groups.view_all_posts')}
            title={t('groups.view_all_posts')}
          >
            <ClipboardList size={26} className="posts-icon" />
          </button>
          
          <button
            onClick={onToggleBotResponses}
            className="bot-toggle-button"
            aria-label={botResponsesEnabled ? t('groups.disable_bot') : t('groups.enable_bot')}
            title={botResponsesEnabled ? t('groups.disable_bot') : t('groups.enable_bot')}
          >
            {botResponsesEnabled ? (
              <Bot size={29} className="bot-icon enabled" />
            ) : (
              <BotOff size={29} className="bot-icon disabled" />
            )}
          </button>
        </div>
      </div>
    </header>
  );
};

export default GroupHeader;
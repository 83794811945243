// src/components/PostList.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PostItem from './PostItem';
import { useHits, useSearchBox, useInstantSearch } from 'react-instantsearch';
import { usePost } from '../hooks/usePost';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const PostList = ({ defaultPosts, isCurrentUser, activeTab, onPostDeleted }) => {
  const { t } = useTranslation();
  const { hits } = useHits();
  const { query } = useSearchBox();
  const { posts } = usePost();
  const { refresh } = useInstantSearch();

  // State to keep track of deleted post IDs and user's friends
  const [deletedPostIds, setDeletedPostIds] = useState([]);
  const [userFriends, setUserFriends] = useState([]);

  // Fetch user's friends on component mount
  useEffect(() => {
    const fetchUserFriends = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUserFriends(userDoc.data().friends || []);
        }
      }
    };
    fetchUserFriends();
  }, []);

  // Define a local handlePostDelete function
  const handlePostDelete = (deletedPostId) => {
    onPostDeleted(deletedPostId); // Call the parent function
    setDeletedPostIds((prevIds) => [...prevIds, deletedPostId]); // Add deleted post ID to state
    refresh(); // Refresh the search results
  };

  // Filter posts based on privacy settings
  const filterPostsByPrivacy = (posts) => {
    const currentUser = auth.currentUser;
    if (!currentUser) return [];

    return posts.filter(post => {
      // Skip deleted posts
      if (deletedPostIds.includes(post.objectID || post.id)) return false;

      // Always show user's own posts
      if (post.userId === currentUser.uid) return true;

      switch (post.privacy) {
        case 'public':
          return true;
        case 'friends':
          return userFriends.includes(post.userId);
        case 'private':
          return false;
        default:
          return true;
      }
    });
  };

  // Filter and prepare posts for display
  const displayPosts = query
    ? filterPostsByPrivacy(hits)
    : (activeTab === 'posts' ? (posts.length > 0 ? posts : defaultPosts) : defaultPosts);

  return (
    <div>
      <h3>{t('posts.title')}</h3>
      {displayPosts.length === 0 ? (
        <p>{t('posts.no_posts')}</p>
      ) : (
        displayPosts.map(post => (
          <PostItem
            key={post.objectID || post.id}
            post={post}
            isCurrentUser={isCurrentUser}
            onPostDeleted={handlePostDelete} // Use the local function
          />
        ))
      )}
    </div>
  );
};

export default PostList;
// src/pages/GroupChatPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import GroupChatInterface from '../components/GroupChatInterface';
import SkeletonGroupChat from '../components/SkeletonGroupChat';
import '../styles/GroupChatPage.css';

const GroupChatPage = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [group, setGroup] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadGroup = async () => {
      try {
        const groupDoc = await getDoc(doc(db, 'groups', groupId));
        
        if (!groupDoc.exists()) {
          setError(t('groups.not_found'));
          return;
        }

        setGroup({
          id: groupDoc.id,
          ...groupDoc.data()
        });
      } catch (err) {
        console.error('Error loading group:', err);
        setError(t('groups.load_error'));
      } finally {
        setLoading(false);
      }
    };

    loadGroup();
  }, [groupId, t]);

  const handleBack = () => {
    navigate('/groups');
  };

  if (loading) {
    return <SkeletonGroupChat />;
  }

  if (error) {
    return (
      <div className="error-container">
        <p className="error-message">{error}</p>
        <button className="back-button" onClick={handleBack}>
          {t('common.back')}
        </button>
      </div>
    );
  }

  return (
    <div className="group-chat-page">
      <GroupChatInterface 
        group={group}
        onBack={handleBack}
      />
    </div>
  );
};

export default GroupChatPage;
// src/components/FriendList/FriendList.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import FriendItem from '../FriendItem';
import FriendSkeleton from '../FriendSkeleton/FriendSkeleton';
import './FriendList.css';

const FriendList = ({ 
  friends, 
  isLoading, 
  shouldHighlightFriend, 
  onFriendClick 
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className="friend-list">
        <h3>{t('friends.my_friends')}</h3>
        <ul>
          <FriendSkeleton count={5} />
        </ul>
      </div>
    );
  }

  if (friends.length === 0) {
    return (
      <div className="friend-list">
        <h3>{t('friends.my_friends')}</h3>
        <p className="friend-list-empty">{t('friends.no_friends')}</p>
      </div>
    );
  }

  return (
    <div className="friend-list">
      <h3>{t('friends.my_friends')}</h3>
      <ul>
        {friends.map((friend) => (
          <FriendItem
            key={friend.id}
            friend={friend}
            shouldHighlight={shouldHighlightFriend(friend)}
            onClick={onFriendClick}
          />
        ))}
      </ul>
    </div>
  );
};

export default FriendList;
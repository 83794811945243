// src/components/ProfileImageUpload.js

import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Camera, X } from 'lucide-react';
import Cropper from 'react-easy-crop';
import './ProfileImageUpload.css';

const ProfileImageUpload = ({ initialImage, onImageSelect }) => {
  const { t } = useTranslation();
  const [image, setImage] = useState(initialImage || null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showCropModal, setShowCropModal] = useState(false);
  const [cropImage, setCropImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);
      image.src = url;
    });

  const handleFileSelect = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        setCropImage(reader.result);
        setShowCropModal(true);
      };

      reader.readAsDataURL(file);
    }
  };

  const getCroppedImg = useCallback(async (imageSrc, pixelCrop) => {
    try {
      const image = await createImage(imageSrc);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg', 0.8);
      });
    } catch (error) {
      console.error('Error in getCroppedImg:', error);
      throw error;
    }
  }, []);

  const handleCropConfirm = useCallback(async () => {
    try {
      if (!croppedAreaPixels) return;

      const croppedImageBlob = await getCroppedImg(cropImage, croppedAreaPixels);
      const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
      
      setImage(croppedImageUrl);
      onImageSelect(croppedImageBlob);
      setShowCropModal(false);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  }, [croppedAreaPixels, cropImage, onImageSelect, getCroppedImg]);

  return (
    <div className="profile-image-upload">
      <div className="profile-image-container">
        {image ? (
          <img src={image} alt={t('profile.profile_picture_alt')} className="profile-image" />
        ) : (
          <div className="profile-image-placeholder">
            <Camera size={32} />
          </div>
        )}
        <label htmlFor="profile-image-input" className="profile-image-edit-button">
          <Camera size={16} />
        </label>
        <input
          type="file"
          id="profile-image-input"
          className="file-input"
          onChange={handleFileSelect}
          accept="image/*"
        />
      </div>

      {showCropModal && (
        <div className="crop-modal">
          <div className="crop-modal-header">
            <button 
              className="crop-control-button secondary"
              onClick={() => setShowCropModal(false)}
            >
              <X size={24} />
            </button>
            <h2 className="crop-modal-title">{t('profile.crop_image')}</h2>
            <button 
              className="crop-control-button primary"
              onClick={handleCropConfirm}
            >
              {t('profile.done')}
            </button>
          </div>

          <div className="crop-container">
            <Cropper
              image={cropImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              cropShape="round"
              showGrid={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileImageUpload;
// src/components/LanguageSwitcher/LanguageSwitcher.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Globe } from 'lucide-react';
import { auth, db } from '../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import './LanguageSwitcher.css';

const LanguageSwitcher = ({ onClose, className }) => {
  const { i18n } = useTranslation();

  const languages = [
    { code: 'en', label: 'English' },
    { code: 'ja', label: '日本語' },
    // { code: 'fr', label: 'FR' },
  ];

  const changeLanguage = async (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);

    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        languagePreference: lng,
      });
    }
    onClose();
  };

  return (
    <div className={`settings-section ${className || ''}`}>
      {languages.map((lang) => (
        <button
          key={lang.code}
          onClick={() => changeLanguage(lang.code)}
          className={`settings-button ${i18n.language === lang.code ? 'active' : ''}`}
        >
          <Globe size={18} className="settings-button-icon" />
          <span className="settings-button-content">
            {lang.label}
          </span>
        </button>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
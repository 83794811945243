// src/components/SkeletonGroupChat.js

import React from 'react';
import './SkeletonGroupChat.css';

const SkeletonMessage = ({ sent }) => (
  <div className={`message-skeleton ${sent ? 'sent' : ''}`}>
    {!sent && <div className="skeleton-avatar" />}
    {!sent && <div className="skeleton-name" />}
    <div className="skeleton-message" />
  </div>
);

const SkeletonGroupChat = () => {
  return (
    <div className="skelton-chat-interface">
      <div className="message-list">
        <SkeletonMessage sent={true} />
        <SkeletonMessage sent={false} />
        <SkeletonMessage sent={false} />
        <SkeletonMessage sent={true} />
        <SkeletonMessage sent={false} />
      </div>
    </div>
  );
};

export default SkeletonGroupChat;
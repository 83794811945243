import React from 'react';
import './ProfileSkeleton.css';

const ProfileSkeleton = () => {
  return (
    <div className="profile-skeleton-container">
      <div className="profile-skeleton-header">
        <div className="profile-skeleton-avatar"></div>
        <div className="profile-skeleton-info">
          <div className="profile-skeleton-name"></div>
          <div className="profile-skeleton-username"></div>
          <div className="profile-skeleton-bio"></div>
          <div className="profile-skeleton-button"></div>
        </div>
      </div>

      <div className="profile-skeleton-tabs">
        <div className="profile-skeleton-tab active"></div>
        <div className="profile-skeleton-tab"></div>
      </div>

      <div className="profile-skeleton-content">
        {[1, 2, 3].map((index) => (
          <div key={index} className="profile-skeleton-post">
            <div className="profile-skeleton-post-header">
              <div className="profile-skeleton-post-avatar"></div>
              <div className="profile-skeleton-post-meta">
                <div className="profile-skeleton-post-title"></div>
                <div className="profile-skeleton-post-date"></div>
              </div>
            </div>
            <div className="profile-skeleton-post-content">
              <div className="profile-skeleton-post-text"></div>
              <div className="profile-skeleton-post-text short"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileSkeleton;
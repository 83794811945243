// src/pages/Friends.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import FriendSearch from '../components/FriendSearch/FriendSearch';
import FriendList from '../components/FriendList/FriendList';
import '../styles/Friends.css';

const Friends = () => {
  const [friends, setFriends] = useState([]);
  const [friendsLastViewedAt, setFriendsLastViewedAt] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFriends = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data();
          const friendIds = userData.friends || [];
          const lastViewedAt = userData.friendsLastViewedAt || {};

          setFriendsLastViewedAt(lastViewedAt);

          const friendsData = await Promise.all(
            friendIds.map(async (friendId) => {
              const friendDoc = await getDoc(doc(db, 'users', friendId));
              return { id: friendId, ...friendDoc.data() };
            })
          );

          // Sort friends based on lastPostUpdatedAt
          friendsData.sort((a, b) => {
            const timeA = a.lastPostUpdatedAt ? a.lastPostUpdatedAt.toMillis() : 0;
            const timeB = b.lastPostUpdatedAt ? b.lastPostUpdatedAt.toMillis() : 0;
            return timeB - timeA; // Descending order
          });

          setFriends(friendsData);
        } catch (error) {
          console.error('Error fetching friends:', error);
          // You might want to add error handling UI here
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchFriends();
  }, []);

  const handleFriendClick = async (friendId) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const updatedFriendsLastViewedAt = {
          ...friendsLastViewedAt,
          [friendId]: serverTimestamp(),
        };

        await updateDoc(userDocRef, {
          friendsLastViewedAt: updatedFriendsLastViewedAt,
        });

        // Navigate to the friend's profile
        navigate(`/profile/${friendId}`);
      } catch (error) {
        console.error('Error updating last viewed timestamp:', error);
        // Still navigate even if the timestamp update fails
        navigate(`/profile/${friendId}`);
      }
    }
  };

  const shouldHighlightFriend = (friend) => {
    const lastViewed = friendsLastViewedAt[friend.id];
    const lastPostUpdatedAt = friend.lastPostUpdatedAt;

    if (lastPostUpdatedAt && lastViewed) {
      return lastPostUpdatedAt.toMillis() > lastViewed.toMillis();
    } else if (lastPostUpdatedAt && !lastViewed) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="friends-page">
      <FriendSearch />
      <FriendList
        friends={friends}
        isLoading={isLoading}
        shouldHighlightFriend={shouldHighlightFriend}
        onFriendClick={handleFriendClick}
      />
    </div>
  );
};

export default Friends;

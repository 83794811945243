// src/pages/Profile.js

import React, { useState, useEffect } from 'react';
import ProfileSkeleton from '../components/ProfileSkeleton/ProfileSkeleton';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { InstantSearch, Configure } from 'react-instantsearch';
import searchClient, { ALGOLIA_INDEX_NAME } from '../config/algoliaConfig';
import ProfileForm from '../components/ProfileForm/ProfileForm';
import ProfileHeader from '../components/ProfileHeader/ProfileHeader';
import PostList from '../components/PostList';
import SearchBar from '../components/SearchBar';
import { PostProvider } from '../contexts/PostContext';
import { usePost } from '../hooks/usePost';
import useUserProfile from '../hooks/useUserProfile';
import useUserPosts from '../hooks/useUserPosts';
import '../styles/Profile.css';

const Profile = ({ initialTab = 'posts' }) => {
  const { t } = useTranslation();
  const { userId: paramUserId } = useParams();
  const { auth } = require('../firebase');
  const currentUser = auth.currentUser;
  const profileUserId = paramUserId || (currentUser && currentUser.uid);

  const { profile, isCurrentUser, loadingProfile, profileError, setProfile } = useUserProfile(profileUserId);
  const {
    userPosts,
    savedPosts,
    savedPostIds,
    loadingPosts,
    postsError,
    setUserPosts,
  } = useUserPosts(profileUserId);

  const [editing, setEditing] = useState(false);
  const [activeTab, setActiveTab] = useState(initialTab);
  const [searchState, setSearchState] = useState({});
  const [friendshipStatus, setFriendshipStatus] = useState('none');

  const { setPosts } = usePost();

  useEffect(() => {
    const checkFriendshipStatus = async () => {
      if (!currentUser || isCurrentUser) return;
      
      try {
        if (profile.friends?.includes(currentUser.uid)) {
          setFriendshipStatus('friends');
        } else if (profile.friendRequests?.includes(currentUser.uid)) {
          setFriendshipStatus('requested');
        } else {
          setFriendshipStatus('none');
        }
      } catch (error) {
        console.error("Error checking friendship status:", error);
      }
    };

    if (profile) {
      checkFriendshipStatus();
    }
  }, [profile, currentUser, isCurrentUser]);

  const handleSearchStateChange = (nextSearchState) => {
    setSearchState(nextSearchState);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleEditProfile = () => {
    setEditing(true);
  };

  const handleCancelEdit = () => {
    setEditing(false);
  };

  const handleProfileUpdate = (updatedProfile) => {
    setProfile(updatedProfile);
    setEditing(false);
  };

  const handlePostDelete = (deletedPostId) => {
    setUserPosts((currentPosts) => currentPosts.filter((post) => post.id !== deletedPostId));
    setPosts((currentPosts) => currentPosts.filter((post) => post.id !== deletedPostId));
  };

  if (loadingProfile || loadingPosts) {
    return <ProfileSkeleton />;
  }

  if (profileError || postsError) {
    return (
      <div className="profile-error">
        {t('error')}: {profileError || postsError}
      </div>
    );
  }

  if (!profile) {
    return <div className="profile-error">{t('profile.not_found')}</div>;
  }

  const filterString =
    activeTab === 'saved'
      ? `objectID:${savedPostIds.join(' OR objectID:')}`
      : `userId:${profileUserId}`;

      return (
        <PostProvider>
          <div className="profile-container">
            {editing ? (
              <ProfileForm
                existingProfile={profile}
                onProfileUpdate={handleProfileUpdate}
                onCancel={handleCancelEdit}
              />
            ) : (
              <ProfileHeader
                user={{ ...profile, id: profileUserId }}
                isOwnProfile={isCurrentUser}
                friendshipStatus={friendshipStatus}
                onEditProfile={handleEditProfile}
              />
            )}
    
            <div className="profile-tabs">
              <button
                className={`profile-tab ${activeTab === 'posts' ? 'active' : ''}`}
                onClick={() => handleTabChange('posts')}
              >
                {t('posts.title')}
              </button>
              {isCurrentUser && (
                <button
                  className={`profile-tab ${activeTab === 'saved' ? 'active' : ''}`}
                  onClick={() => handleTabChange('saved')}
                >
                  {t('profile.saved_posts')}
                </button>
              )}
            </div>
    
            <InstantSearch
              searchClient={searchClient}
              indexName={ALGOLIA_INDEX_NAME}
              searchState={searchState}
              onSearchStateChange={handleSearchStateChange}
            >
              <Configure filters={filterString} />
              <div className="profile-posts-search">
                <SearchBar />
              </div>
              <div className="profile-content">
                <PostList
                  defaultPosts={activeTab === 'posts' ? userPosts : savedPosts}
                  isCurrentUser={isCurrentUser}
                  activeTab={activeTab}
                  onPostDeleted={handlePostDelete}
                />
              </div>
            </InstantSearch>
          </div>
        </PostProvider>
      );
    };
    
    export default Profile;
// src/pages/AgentChatPage.js

import React from 'react';
import AgentChatInterface from '../components/AgentChatInterface';

const AgentChatPage = () => {
  return (
    <div className="agent-chat-page">
      <AgentChatInterface />
    </div>
  );
};

export default AgentChatPage;
// src/components/FriendSkeleton/FriendSkeleton.js
import React from 'react';
import './FriendSkeleton.css';

const FriendSkeleton = ({ count = 3 }) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div key={index} className="friend-skeleton">
          <div className="friend-skeleton-left">
            <div className="friend-skeleton-avatar"></div>
            <div className="friend-skeleton-info">
              <div className="friend-skeleton-name"></div>
              <div className="friend-skeleton-username"></div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default FriendSkeleton;
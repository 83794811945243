// src/components/FriendRequests/FriendRequests.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  arrayRemove, 
  arrayUnion, 
  onSnapshot 
} from 'firebase/firestore';
import DefaultAvatar from '../DefaultAvatar';
import './FriendRequests.css';

const FriendRequests = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const [error, setError] = useState('');
  const [processingRequests, setProcessingRequests] = useState(new Set());
  
  useEffect(() => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const userDocRef = doc(db, 'users', currentUser.uid);
    
    const unsubscribe = onSnapshot(userDocRef, async (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        const requestIds = userData.friendRequests || [];
        
        try {
          const requestsData = await Promise.all(
            requestIds.map(async (requestId) => {
              const requestDoc = await getDoc(doc(db, 'users', requestId));
              return { id: requestId, ...requestDoc.data() };
            })
          );
          setRequests(requestsData);
        } catch (err) {
          console.error("Error fetching friend requests:", err);
          setError(t('friends.load_error'));
        }
      }
    }, (error) => {
      console.error("Error listening to friend requests:", error);
      setError(t('friends.update_error'));
    });

    return () => unsubscribe();
  }, [t]);

  const handleRequestResponse = async (requesterId, accept) => {
    setProcessingRequests(prev => new Set(prev).add(requesterId));
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      const currentUserRef = doc(db, 'users', currentUser.uid);
      const requesterRef = doc(db, 'users', requesterId);

      if (accept) {
        await updateDoc(currentUserRef, {
          friendRequests: arrayRemove(requesterId),
          friends: arrayUnion(requesterId)
        });

        await updateDoc(requesterRef, {
          sentFriendRequests: arrayRemove(currentUser.uid),
          friends: arrayUnion(currentUser.uid)
        });
      } else {
        await updateDoc(currentUserRef, {
          friendRequests: arrayRemove(requesterId)
        });

        await updateDoc(requesterRef, {
          sentFriendRequests: arrayRemove(currentUser.uid)
        });
      }
    } catch (error) {
      console.error("Error handling friend request:", error);
      setError(t('friends.process_error'));
    } finally {
      setProcessingRequests(prev => {
        const newSet = new Set(prev);
        newSet.delete(requesterId);
        return newSet;
      });
    }
  };

  const handleUserClick = (userId, e) => {
    // Check if the click was on a button
    if (e.target.closest('button')) {
      return;
    }
    navigate(`/profile/${userId}`);
  };

  const getDisplayName = (user) => {
    if (user.displayName) return user.displayName;
    if (user.username) return user.username;
    return t('friends.anonymous_friend');
  };

  if (requests.length === 0) {
    return null;
  }

  return (
    <div className="friend-requests">
      <h2 className="friend-requests-title">{t('friends.friend_requests')}</h2>
      {error && <div className="friend-requests-error">{error}</div>}
      
      <div className="friend-requests-list">
        {requests.map((request) => (
          <div 
            key={request.id} 
            className="friend-request-card"
            onClick={(e) => handleUserClick(request.id, e)}
            role="button"
            tabIndex="0"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleUserClick(request.id, e);
              }
            }}
          >
            <div className="friend-request-user">
              <div className="friend-request-avatar">
                {request.profilePictureUrl ? (
                  <img 
                    src={request.profilePictureUrl} 
                    alt={t('friends.profile_picture_alt', { name: getDisplayName(request) })}
                    className="friend-request-avatar-image"
                    loading="lazy"
                  />
                ) : (
                  <DefaultAvatar
                    name={getDisplayName(request)}
                    size={45}
                    fallbackColor="var(--color-primary-light)"
                    textColor="var(--color-surface)"
                  />
                )}
              </div>
              <div className="friend-request-info">
                <h3>{getDisplayName(request)}</h3>
                {request.username && (
                  <p className="friend-request-username">@{request.username}</p>
                )}
              </div>
            </div>
            <div className="friend-request-actions">
              <button
                className="friend-request-button accept"
                onClick={() => handleRequestResponse(request.id, true)}
                disabled={processingRequests.has(request.id)}
              >
                {processingRequests.has(request.id) ? t('friends.processing') : t('friends.accept')}
              </button>
              <button
                className="friend-request-button decline"
                onClick={() => handleRequestResponse(request.id, false)}
                disabled={processingRequests.has(request.id)}
              >
                {t('friends.decline')}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FriendRequests;
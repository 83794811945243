import React, { useEffect, useState } from 'react';
import { collection, query, getDocs, orderBy, doc, getDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { db } from '../firebase';
import { X } from 'lucide-react';
import GroupChatPostList from './GroupChatPostList';
import GroupChatPostListSkeleton from './GroupChatPostSkeleton';
import './AllPostsInGroup.css';

const AllPostsInGroup = ({ groupId, onClose }) => {
  const { t } = useTranslation();
  const [postIds, setPostIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGroupPosts = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        // First, get post IDs from the group's posts subcollection
        const groupPostsRef = collection(db, `groups/${groupId}/posts`);
        const q = query(groupPostsRef, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        
        // Get post IDs and verify each post exists
        const postPromises = querySnapshot.docs.map(async (docSnap) => {
          const postId = docSnap.data().postId;
          const postRef = doc(db, 'posts', postId);
          const postSnap = await getDoc(postRef);
          
          // Only include posts that exist and are accessible
          if (postSnap.exists()) {
            return postId;
          }
          return null;
        });

        // Wait for all post verifications and filter out null values
        const verifiedIds = (await Promise.all(postPromises)).filter(id => id !== null);
        setPostIds(verifiedIds);
      } catch (error) {
        console.error('Error fetching group posts:', error);
        setError(t('groups.posts_load_error'));
      } finally {
        setIsLoading(false);
      }
    };

    if (groupId) {
      fetchGroupPosts();
    }
  }, [groupId, t]);

  return (
    <div className="all-posts-overlay">
      <div className="all-posts-modal">
        <div className="all-posts-header">
          <h3 className="all-posts-title">{t('groups.all_posts_title')}</h3>
          <button
            onClick={onClose}
            className="all-posts-close-button"
            aria-label={t('common.close')}
          >
            <X size={24} />
          </button>
        </div>
        
        <div className="all-posts-content">
          {error ? (
            <div className="all-posts-error">{error}</div>
          ) : isLoading ? (
            <GroupChatPostListSkeleton />
          ) : postIds.length === 0 ? (
            <div className="all-posts-empty">{t('groups.no_posts')}</div>
          ) : (
            <GroupChatPostList postIds={postIds} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllPostsInGroup;
// src/components/ProfileHeader/SettingsMenu.js
import React from 'react';
import { Settings, LogOut, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import './SettingsMenu.css';

const SettingsMenu = ({ isOpen, onClose, onLogout }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <>
      {/* Backdrop */}
      <div 
        className="settings-backdrop"
        onClick={onClose}
      />
      
      {/* Menu Container */}
      <div className={`settings-container ${!isOpen ? 'hidden' : ''}`}>
        <div className="settings-content">
          {/* Header */}
          <div className="settings-header">
            <div className="settings-title">
              <Settings size={20} />
              <span>{t('settings.title')}</span>
            </div>
            <button 
              onClick={onClose}
              className="settings-close-button"
              aria-label={t('common.close')}
            >
              <X size={20} />
            </button>
          </div>
          
          {/* Content */}
          <div className="settings-body">
            {/* Language Section */}
            <div className="settings-section">
              <div className="settings-section-title">
                {t('settings.language')}
              </div>
              <LanguageSwitcher onClose={onClose} />
            </div>

            {/* Logout Button */}
            <button
              onClick={onLogout}
              className="settings-button settings-logout"
            >
              <LogOut size={18} className="settings-button-icon" />
              <span className="settings-button-content">{t('auth.logout')}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsMenu;
// src/components/ProfileHeader/ProfileHeader.js
import React, { useState } from 'react';
import { User, Settings, UserPlus, UserCheck, Clock } from 'lucide-react';
import { doc, setDoc, arrayUnion } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { auth, db } from '../../firebase';
import SettingsMenu from './SettingsMenu';
import './ProfileHeader.css';

const ProfileHeader = ({ 
  user, 
  isOwnProfile,
  friendshipStatus = 'none',
  onEditProfile,
  onLogout,
  className 
}) => {
  const { t } = useTranslation();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleSendRequest = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) return;

      await setDoc(doc(db, 'users', user.id), {
        friendRequests: arrayUnion(currentUser.uid)
      }, { merge: true });

      await setDoc(doc(db, 'users', currentUser.uid), {
        sentFriendRequests: arrayUnion(user.id)
      }, { merge: true });
    } catch (error) {
      console.error("Error sending friend request:", error);
    }
  };

  const renderActionButton = () => {
    if (isOwnProfile) {
      return (
        <button 
          onClick={onEditProfile}
          className="profile-action-button edit-button"
        >
          {t('profile.edit_profile')}
        </button>
      );
    }

    switch (friendshipStatus) {
      case 'friends':
        return (
          <button 
            className="profile-action-button connected-button"
            disabled
          >
            <UserCheck className="profile-action-button-icon" />
            {t('friends.connected')}
          </button>
        );
      case 'requested':
        return (
          <button 
            className="profile-action-button requested-button"
            disabled
          >
            <Clock className="profile-action-button-icon" />
            {t('friends.requested')}
          </button>
        );
      default:
        return (
          <button 
            onClick={handleSendRequest}
            className="profile-action-button connect-button"
          >
            <UserPlus className="profile-action-button-icon" />
            {t('friends.connect')}
          </button>
        );
    }
  };

  return (
    <div className={`profile-header ${className || ''}`}>
      {isOwnProfile && (
        <>
        <button 
          onClick={() => setIsSettingsOpen(true)} 
          className="profile-settings-button"
          aria-label={t('settings.title')}
        >
          <Settings className="profile-settings-icon" />
        </button>
        <SettingsMenu 
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          onLogout={onLogout}
        />
      </>
    )}
      
      <div className="profile-header-content">
        <div className="profile-header-main">
          <div className="profile-info-container">
            <div className="profile-picture-container">
              <div className="profile-picture">
                {user.profilePictureUrl ? (
                  <img 
                    src={user.profilePictureUrl} 
                    alt={t('profile.profile_picture_alt', { name: user.displayName })}
                  />
                ) : (
                  <User className="profile-picture-placeholder" />
                )}
              </div>
            </div>
            
            <div className="profile-info">
              <div>
                <h1 className="profile-name">{user.displayName}</h1>
                <span className="profile-username">@{user.username}</span>
              </div>
              <p className="profile-bio">{user.bio}</p>
            </div>
          </div>
        </div>
        
        {renderActionButton()}
      </div>
    </div>
  );
};

export default ProfileHeader;
// src/components/GroupChatPostSkeleton.js
import React from 'react';
import './GroupChatPostSkeleton.css';

const GroupChatPostSkeleton = () => (
  <div className="post-skeleton">
    <div className="post-skeleton-header">
      <div className="post-skeleton-avatar"></div>
      <div className="post-skeleton-info">
        <div className="post-skeleton-username"></div>
        <div className="post-skeleton-timestamp"></div>
      </div>
    </div>
  </div>
);

const GroupChatPostListSkeleton = () => (
  <div className="group-chat-post-list">
    {[...Array(10)].map((_, i) => (
      <GroupChatPostSkeleton key={i} />
    ))}
  </div>
);

export { GroupChatPostSkeleton };
export default GroupChatPostListSkeleton;
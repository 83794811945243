// src/components/GroupChatPostList.js

import React, { useEffect, useState, useCallback } from 'react';
import GroupChatPostItem from './GroupChatPostItem';
import { GroupChatPostSkeleton } from './GroupChatPostSkeleton';
import { db } from '../firebase';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import './GroupChatPostList.css';

const GroupChatPostList = ({ postIds }) => {
  const [postsById, setPostsById] = useState({});

  useEffect(() => {
    if (!postIds || postIds.length === 0) return;

    // Create an object to store our unsubscribe functions
    const unsubscribes = new Map();
    const authorDataCache = new Map();

    // Function to fetch author data
    const fetchAuthorData = async (userId) => {
      if (authorDataCache.has(userId)) {
        return authorDataCache.get(userId);
      }

      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);
      const authorData = userSnap.exists() ? {
        id: userSnap.id,
        ...userSnap.data()
      } : null;

      authorDataCache.set(userId, authorData);
      return authorData;
    };

    // Set up real-time listeners for each post
    postIds.forEach((postId) => {
      const postRef = doc(db, 'posts', postId);
      
      const unsubscribe = onSnapshot(postRef, async (postSnap) => {
        if (postSnap.exists()) {
          const postData = postSnap.data();
          
          try {
            const authorData = await fetchAuthorData(postData.userId);
            
            const updatedPost = {
              id: postSnap.id,
              ...postData,
              author: authorData ? {
                id: authorData.id,
                username: authorData.username,
                displayName: authorData.displayName,
                profilePictureUrl: authorData.profilePictureUrl
              } : null
            };

            setPostsById(current => ({
              ...current,
              [postId]: updatedPost
            }));
          } catch (error) {
            console.error('Error fetching author data:', error);
          }
        } else {
          console.warn(`Post with ID ${postId} does not exist.`);
          // Remove the post from state if it no longer exists
          setPostsById(current => {
            const { [postId]: removed, ...rest } = current;
            return rest;
          });
        }
      }, (error) => {
        console.error(`Error listening to post ${postId}:`, error);
      });

      unsubscribes.set(postId, unsubscribe);
    });

    // Cleanup function to unsubscribe from all listeners
    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [postIds]);

  const handlePostUpdate = useCallback((updatedPost) => {
    setPostsById(current => ({
      ...current,
      [updatedPost.id]: { ...current[updatedPost.id], ...updatedPost }
    }));
  }, []);

  // Check if postIds is empty and return null
  if (!postIds || postIds.length === 0) {
    return null;
  }

  return (
    <div className="group-chat-post-list">
      {postIds.map((id) => {
        const post = postsById[id];
        if (!post) {
          return <GroupChatPostSkeleton key={id} />;
        }
        return (
          <GroupChatPostItem 
            key={id} 
            post={post} 
            onPostUpdated={handlePostUpdate}
          />
        );
      })}
    </div>
  );
};

export default GroupChatPostList;

// src/components/ProfileForm/ProfileForm.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { auth, db, storage } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { X, Check } from 'lucide-react';
import ProfileImageUpload from './ProfileImageUpload';
import { validateUsername } from '../../utils/validation';
import './ProfileForm.css';

const ProfileForm = ({ existingProfile, onProfileUpdate, onCancel }) => {
  const { t } = useTranslation();
  const [displayName, setDisplayName] = useState(existingProfile?.displayName || '');
  const [username, setUsername] = useState(existingProfile?.username || '');
  const [originalUsername, setOriginalUsername] = useState(existingProfile?.username || '');
  const [bio, setBio] = useState(existingProfile?.bio || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({});
  const [touched, setTouched] = useState({});
  const [profilePicture, setProfilePicture] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (existingProfile) {
      setDisplayName(existingProfile.displayName || '');
      setUsername(existingProfile.username || '');
      setOriginalUsername(existingProfile.username || '');
      setBio(existingProfile.bio || '');
    }
  }, [existingProfile]);

  const handleUsernameChange = (e) => {
    const lowercaseInput = e.target.value.toLowerCase();
    setUsername(lowercaseInput);
    setTouched({ ...touched, username: true });
  };

  const handleImageSelect = (file) => {
    setProfilePicture(file);
  };

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
  };

  const validateForm = () => {
    const errors = {};
    if (!displayName.trim()) errors.displayName = 'profile.display_name_required';
    const usernameError = validateUsername(username);
    if (usernameError) errors.username = usernameError;
    return errors;
  };

  const handleSubmit = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setError(formErrors);
      return;
    }

    setIsSubmitting(true);
    setError({});

    const user = auth.currentUser;
    if (!user) {
      setError({ general: 'errors.user_not_authenticated' });
      setIsSubmitting(false);
      return;
    }

    try {
      let updatedUsername = originalUsername;
      if (username !== originalUsername) {
        const functions = getFunctions();
        const setUsernameFunction = httpsCallable(functions, 'setUsername');
        const result = await setUsernameFunction({ username });
        if (!result.data.success) {
          throw new Error('profile.username_set_failed');
        }
        updatedUsername = username;
      }

      let profilePictureUrl = existingProfile?.profilePictureUrl || '';
      if (profilePicture) {
        const compressedFile = await imageCompression(profilePicture, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        });

        const storageRef = ref(storage, `profilePictures/${user.uid}/profile.jpg`);
        await uploadBytes(storageRef, compressedFile);
        profilePictureUrl = await getDownloadURL(storageRef);
      }

      const profileData = {
        displayName,
        username: updatedUsername,
        bio,
        profilePictureUrl,
        updatedAt: new Date(),
      };

      await setDoc(doc(db, 'users', user.uid), profileData, { merge: true });
      setOriginalUsername(updatedUsername);
      onProfileUpdate(profileData);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Error updating profile: ", error);
      setError({ general: error.message || 'profile.update_failed' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="profile-form-container">
      <header className="profile-form-header">
        <button 
          onClick={onCancel}
          className="profile-form-close-button"
          aria-label={t('common.cancel')}
        >
          <X size={24} />
        </button>
        <h1 className="profile-form-title">{t('profile.edit_profile')}</h1>
        <button 
          onClick={handleSubmit}
          className="profile-form-submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? t('profile.saving') : t('common.done')}
        </button>
      </header>
      
      <div className="profile-form-content">
        <section className="profile-photo-section">
          <ProfileImageUpload
            initialImage={existingProfile?.profilePictureUrl}
            onImageSelect={handleImageSelect}
          />
        </section>

        <div className="profile-form-fields">
          <div className="form-field-group">
            <div className="form-field">
              <label htmlFor="displayName">{t('profile.display_name')}</label>
              <input
                id="displayName"
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                onBlur={() => handleBlur('displayName')}
                placeholder={t('profile.enter_display_name')}
                aria-invalid={touched.displayName && !displayName.trim()}
              />
            </div>
            {touched.displayName && !displayName.trim() && (
              <p className="form-error">{t('profile.display_name_required')}</p>
            )}
          </div>

          <div className="form-field-group">
            <div className="form-field">
              <label htmlFor="username">{t('profile.username')}</label>
              <input
                id="username"
                type="text"
                value={username}
                onChange={handleUsernameChange}
                onBlur={() => handleBlur('username')}
                placeholder={t('profile.enter_username')}
                aria-invalid={touched.username && !!validateUsername(username)}
              />
            </div>
            {touched.username && validateUsername(username) && (
              <p className="form-error">{t(validateUsername(username))}</p>
            )}
          </div>

          <div className="form-field-group">
            <div className="form-field">
              <label htmlFor="bio">{t('profile.bio')}</label>
              <textarea
                id="bio"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                placeholder={t('profile.enter_bio')}
                rows={4}
              />
            </div>
          </div>

          {error.general && (
            <div className="form-general-error" role="alert">
              {t(error.general)}
            </div>
          )}
        </div>
      </div>

      {showSuccess && (
        <div className="success-toast">
          <Check size={16} />
          <span>{t('profile.update_success')}</span>
        </div>
      )}
    </div>
  );
};

export default ProfileForm;
// src/components/AgentChatInterface.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Send, Loader, Image, X } from 'lucide-react';
import { auth, db } from '../firebase';
import { 
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  onSnapshot,
  serverTimestamp,
  getDocs,
  startAfter,
  GeoPoint
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import ImageUploader from './ImageUploader';
import GroupChatPostList from './GroupChatPostList';
import DraftItem from './DraftItem';
import { getLocation } from '../utils/location';
import botIcon from '../assets/icon.png';
import './AgentChatInterface.css';

const MAX_IMAGES = 6;
const MESSAGES_PER_PAGE = 50;

const AgentChatInterface = () => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [error, setError] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [showSendButton, setShowSendButton] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  // Refs
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const topOfListRef = useRef(null);
  const textareaRef = useRef(null);
  const messageListRef = useRef(null);

  // Firebase functions
  const functions = getFunctions();
  const difyAgentChat = httpsCallable(functions, 'difyAgentChat');

  // Subscribe to messages
  useEffect(() => {
    if (!auth.currentUser) return;

    const messagesRef = collection(db, `users/${auth.currentUser.uid}/messages`);
    const q = query(
      messagesRef,
      orderBy('timestamp', 'desc'),
      limit(MESSAGES_PER_PAGE)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = [];

      querySnapshot.forEach((doc) => {
        const messageData = { id: doc.id, ...doc.data() };
        fetchedMessages.push(messageData);
      });

      setMessages(fetchedMessages.reverse());
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMoreMessages(querySnapshot.docs.length === MESSAGES_PER_PAGE);

      setInitialLoadComplete(true);
    });

    return () => unsubscribe();
  }, []);

  // Initial scroll to bottom
  useEffect(() => {
    if (initialLoadComplete && messageListRef.current && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [initialLoadComplete]);

  // Load more messages
  const loadMoreMessages = useCallback(async () => {
    if (!lastVisible || isLoadingMore || !hasMoreMessages || !auth.currentUser) return;

    setIsLoadingMore(true);

    try {
      const messagesRef = collection(db, `users/${auth.currentUser.uid}/messages`);
      const q = query(
        messagesRef,
        orderBy('timestamp', 'desc'),
        startAfter(lastVisible),
        limit(MESSAGES_PER_PAGE)
      );

      const querySnapshot = await getDocs(q);
      const fetchedMessages = [];

      querySnapshot.forEach((doc) => {
        const messageData = { id: doc.id, ...doc.data() };
        fetchedMessages.push(messageData);
      });

      if (fetchedMessages.length > 0) {
        setMessages((prevMessages) => [
          ...fetchedMessages.reverse(),
          ...prevMessages,
        ]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMoreMessages(fetchedMessages.length === MESSAGES_PER_PAGE);
      } else {
        setHasMoreMessages(false);
      }
    } catch (error) {
      console.error('Error loading more messages:', error);
      setError(t('chat.load_more_error'));
    } finally {
      setIsLoadingMore(false);
    }
  }, [lastVisible, isLoadingMore, hasMoreMessages, t]);

  // Set up IntersectionObserver to load more messages when scrolled to top
  useEffect(() => {
    if (!topOfListRef.current || !hasMoreMessages) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoadingMore) {
            loadMoreMessages();
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 1.0 }
    );

    observer.observe(topOfListRef.current);

    return () => {
      observer.disconnect();
    };
  }, [loadMoreMessages, isLoadingMore, hasMoreMessages]);

  // Auto-scroll behavior
  useEffect(() => {
    if (!initialLoadComplete) return;

    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 300;
  
      if (isNearBottom) {
        requestAnimationFrame(() => {
          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        });
      }
    }
  }, [messages.length, initialLoadComplete]);

  // Textarea height adjustment
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '48px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  // Image handling functions
  const handleImageUpload = (imagesData) => {
    setUploadedImages((prevImages) => [...prevImages, ...imagesData]);
  };

  const handleRemoveImage = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleImageIconClick = () => {
    fileInputRef.current.click();
  };

  const handleImageLoad = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  }, []);

  const sendMessage = async (e) => {
    e.preventDefault();
    if ((input.trim() === '' && uploadedImages.length === 0) || !auth.currentUser) return;
  
    setIsLoading(true);
    setError(null);
  
    try {
      const images = uploadedImages.map((image) => ({
        url: image.url,
        name: image.name,
        type: image.type,
      }));

      // Get user location
      const location = await getLocation();
  
      const messageData = {
        text: input,
        sender: 'user',
        type: 'chat',
        timestamp: serverTimestamp(),
        images: images.length > 0 ? images : null,
        location: location ? new GeoPoint(location.lat, location.lng) : null,
      };
  
      // Add the message to the messages sub-collection
      const messagesRef = collection(db, `users/${auth.currentUser.uid}/messages`);
      const newMessageRef = await addDoc(messagesRef, messageData);

      // Reset input and uploaded images
      setInput('');
      setUploadedImages([]);
      setShowSendButton(false);

      // Call difyAgentChat function
      difyAgentChat({
        query: input,
        images: images,
        messageId: newMessageRef.id, // Pass the message ID to link the response
      }).catch((error) => {
        console.error('Error in difyAgentChat:', error);
      });
    } catch (error) {
      console.error('Error sending message:', error);
      setError(t('chat.send_error'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInput(inputValue);
    setShowSendButton(inputValue.trim().length > 0 || uploadedImages.length > 0);
  };

  return (
    <div className="agent-chat-interface">
      <div className="message-list" ref={messageListRef}>
        <div ref={topOfListRef} />
        {isLoadingMore && <div className="loading-more">{t('chat.loading_more')}</div>}
        {messages.map((message) => {
          const isCurrentUser = message.sender === 'user';
          const isBot = message.sender === 'bot';
          
          return (
            <div
              key={message.id}
              className={`message-container ${isCurrentUser ? 'sent' : 'received'} ${isBot ? 'bot' : ''}`}
            >
              {/* Bot avatar */}
              {!isCurrentUser && isBot && (
                <div className="avatar-container">
                  <img
                    src={botIcon}
                    alt={t('chat.agent_icon')}
                    className="agent-profile-picture"
                  />
                </div>
              )}

              {/* Unified message rendering */}
              {(() => {
                switch (message.type) {
                  case 'search_results':
                    return (
                      <GroupChatPostList
                        postIds={message.postIds}
                      />
                    );

                  case 'draft_post':
                    return (
                      <DraftItem
                        draftId={message.draftId}
                      />
                    );

                  // Handle regular chat messages (either from user or bot)
                  case 'chat':
                  default:
                    // Display text, images, etc.
                    return (
                      <div className="message">
                        {message.images && message.images.map((image, idx) => (
                          <img
                            key={idx}
                            src={image.url}
                            alt={image.name || t('chat.uploaded_image', { index: idx + 1 })}
                            className="message-image"
                            onLoad={handleImageLoad}
                          />
                        ))}
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm, remarkBreaks]}
                        >
                          {message.text}
                        </ReactMarkdown>
                      </div>
                    );
                }
              })()}
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="message-input-container">
        {uploadedImages.length > 0 && (
          <div className="uploaded-images-preview">
            {uploadedImages.map((image, index) => (
              <div key={index} className="uploaded-image-container">
                <img 
                  src={image.url} 
                  alt={t('chat.uploaded_image', { index: index + 1 })} 
                  className="uploaded-image" 
                />
                <button
                  onClick={() => handleRemoveImage(index)}
                  className="remove-image-button"
                  aria-label={t('chat.remove_image')}
                >
                  <X size={20} />
                </button>
              </div>
            ))}
          </div>
        )}

        <form onSubmit={sendMessage} className="message-input">
          <button
            type="button"
            onClick={handleImageIconClick}
            className="image-upload-button"
            aria-label={t('chat.upload_images')}
            disabled={uploadedImages.length >= MAX_IMAGES || isLoading}
            title={uploadedImages.length >= MAX_IMAGES 
              ? t('chat.max_images_reached', { max: MAX_IMAGES }) 
              : t('chat.upload_images')}
          >
            <Image size={22} />
          </button>
          
          <textarea
            ref={textareaRef}
            value={input}
            onChange={handleInputChange}
            placeholder={t('chat.type_message')}
            disabled={isLoading}
            rows={1}
          />
          
          <button
            type="submit"
            disabled={isLoading || (input.trim().length === 0 && uploadedImages.length === 0)}
            className={`send-button ${showSendButton ? 'visible' : ''} ${
              isLoading ? 'loading' : ''
            }`}
            aria-label={isLoading ? t('chat.processing_message') : t('chat.send_message')}
          >
            {isLoading ? <Loader size={20} className="loader" /> : <Send size={20} />}
          </button>
        </form>
      </div>

      <ImageUploader
        onImageUpload={handleImageUpload}
        fileInputRef={fileInputRef}
        currentImageCount={uploadedImages.length}
        maxImages={MAX_IMAGES}
      />
    </div>
  );
};

export default AgentChatInterface;
// src/components/Header.js
import React, { useState, useEffect, useCallback } from 'react';
import { Bell } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NotificationsDropdown from './NotificationsDropdown';
import GroupButton from './GroupButton';
import { getUnreadNotificationsCount, markAllNotificationsAsRead } from '../services/notificationService';
import logo from '../assets/logo.png';
import './Header.css';

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const fetchUnreadCount = useCallback(async () => {
    const count = await getUnreadNotificationsCount();
    setUnreadCount(count);
  }, []);

  useEffect(() => {
    fetchUnreadCount();
    const timer = setInterval(fetchUnreadCount, 60000); // Check every minute

    return () => {
      clearInterval(timer);
    };
  }, [fetchUnreadCount]);

  const handleNotificationClick = async () => {
    if (!isNotificationsOpen) {
      await markAllNotificationsAsRead();
      setUnreadCount(0);
    }
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <header className="app-header">
      <button onClick={handleRefresh} className="logo-button" aria-label="Refresh app">
        <img src={logo} alt="Affin Logo" className="logo" />
      </button>
      <div className="header-actions">
        <button 
          className="header-button notifications-button"
          onClick={handleNotificationClick}
          aria-label={t('notifications.title')}
        >
          <Bell size={24} />
          {unreadCount > 0 && (
            <span className="notification-badge">
              {unreadCount > 99 ? '99+' : unreadCount}
            </span>
          )}
        </button>
        <NotificationsDropdown 
          isOpen={isNotificationsOpen} 
          onClose={() => setIsNotificationsOpen(false)}
        />
        <GroupButton onClick={() => navigate('/groups')} />
      </div>
    </header>
  );
};

export default Header;
// src/utils/location.js

const getIpBasedLocation = async () => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    if (!response.ok) {
      throw new Error('IP geolocation request failed');
    }
    
    const data = await response.json();
    return {
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
      source: 'ip', // Adding a source identifier
      accuracy: 'low'
    };
  } catch (error) {
    console.error('IP geolocation error:', error);
    return null;
  }
};

const getBrowserLocation = () => {
  return new Promise((resolve) => {
    if (!navigator.geolocation) {
      // Geolocation not supported; return null
      return resolve(null);
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          source: 'browser',
          accuracy: 'high'
        });
      },
      (error) => {
        console.error('Browser geolocation error:', error);
        // Provide more detailed error information
        let errorType;
        switch (error.code) {
          case 1:
            errorType = 'PERMISSION_DENIED';
            break;
          case 2:
            errorType = 'POSITION_UNAVAILABLE';
            break;
          case 3:
            errorType = 'TIMEOUT';
            break;
          default:
            errorType = 'UNKNOWN';
        }
        resolve({ error: errorType });
      },
      { enableHighAccuracy: false, timeout: 10000, maximumAge: 0 }
    );
  });
};

export const getLocation = async () => {
  // First try browser geolocation
  const browserLocation = await getBrowserLocation();
  
  // If browser geolocation succeeded, return it
  if (browserLocation && !browserLocation.error) {
    return browserLocation;
  }

  // Log the reason for falling back to IP geolocation
  if (browserLocation?.error) {
    console.log(`Falling back to IP geolocation due to: ${browserLocation.error}`);
  }

  // Try IP-based geolocation as fallback
  return await getIpBasedLocation();
};  
// src/components/PostMessage.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import GroupChatPostItem from './GroupChatPostItem';
import './PostMessage.css';

const PostMessage = ({ postId }) => {
  const { t } = useTranslation();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!postId) return;

    setIsLoading(true);
    const postRef = doc(db, 'posts', postId);
    
    const unsubscribe = onSnapshot(postRef, async (docSnap) => {
      try {
        if (docSnap.exists()) {
          const postData = docSnap.data();

          // Fetch the user data for the post's author
          const userRef = doc(db, 'users', postData.userId);
          const userSnap = await getDoc(userRef);

          const authorData = userSnap.exists() ? userSnap.data() : null;

          // Combine post data with author data
          setPost({
            id: docSnap.id,
            ...postData,
            author: authorData ? {
              id: userSnap.id,
              username: authorData.username,
              displayName: authorData.displayName,
              profilePictureUrl: authorData.profilePictureUrl
            } : null
          });
        } else {
          setError(t('posts.not_found'));
        }
      } catch (error) {
        console.error('Error fetching post:', error);
        setError(t('posts.load_error'));
      } finally {
        setIsLoading(false);
      }
    }, (error) => {
      console.error('Error in post snapshot listener:', error);
      setError(t('posts.load_error'));
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [postId, t]);

  if (isLoading) {
    return (
      <div className="post-message loading post-message-loading">
        <div className="loading-skeleton">
          <div className="loading-avatar loading-skeleton" />
          <div className="loading-text">
            <div className="loading-name loading-skeleton" />
            <div className="loading-title loading-skeleton" />
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="post-message error">{error}</div>;
  }

  if (!post) {
    return null;
  }

  return (
    <div className="post-message">
      <GroupChatPostItem post={post} />
    </div>
  );
};

export default PostMessage;
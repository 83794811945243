// src/components/GroupChatInterface.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { Send, Loader, Image, X } from 'lucide-react';
import { format, isToday, isYesterday, formatDistanceToNow } from 'date-fns';
import { auth, db } from '../firebase';
import { 
  collection, 
  addDoc, 
  query, 
  orderBy, 
  limit, 
  onSnapshot,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  startAfter,
  getDocs,
  where,
  GeoPoint
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import GroupHeader from './GroupHeader';
import GroupSettings from './GroupSettings';
import ImageUploader from './ImageUploader';
import DefaultAvatar from './DefaultAvatar';
import GroupChatPostList from './GroupChatPostList';
import DraftItem from './DraftItem';
import PostMessage from './PostMessage';
import AllPostsInGroup from './AllPostsInGroup';
import SkeletonGroupChat from './SkeletonGroupChat';
import { getLocation } from '../utils/location';
import botIcon from '../assets/icon.png';
import './GroupChatInterface.css';

const MAX_IMAGES = 6;

const MessageTimestamp = ({ timestamp }) => {
  if (!timestamp) return null;
  
  const date = timestamp.toDate();
  let timeString = '';

  // For messages less than 1 hour old, show relative time
  const minutesAgo = Math.floor((Date.now() - date.getTime()) / (1000 * 60));
  if (minutesAgo < 60) {
    timeString = formatDistanceToNow(date, { addSuffix: true });
  } else {
    // For other messages, show time only
    timeString = format(date, 'h:mm a');
  }

  return <span className="message-timestamp">{timeString}</span>;
};

const DateSeparator = ({ timestamp, t }) => {
  if (!timestamp) return null;
  
  const date = timestamp.toDate();
  let dateString = '';

  if (isToday(date)) {
    dateString = t('chat.today');
  } else if (isYesterday(date)) {
    dateString = t('chat.yesterday');
  } else {
    dateString = format(date, 'MMMM d');
  }

  return (
    <div className="date-separator">
      <div className="date-separator-content">{dateString}</div>
    </div>
  );
};

const MessageReply = ({ messageId, messages }) => {
  const replyTo = messages.find(msg => msg.id === messageId);
  
  if (!replyTo) return null;

  const handleReplyClick = () => {
    const messageElement = document.getElementById(`message-${messageId}`);
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // Add highlight effect
      messageElement.classList.add('message-highlighted');
      // Remove highlight after animation
      setTimeout(() => {
        messageElement.classList.remove('message-highlighted');
      }, 2000);
    }
  };

  return (
    <div 
      className="message-reply-reference"
      onClick={handleReplyClick}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleReplyClick();
        }
      }}
    >
      <div className="reply-line" />
      <div className="reply-content">
        {replyTo.images && replyTo.images.length > 0 && (
          <Image size={14} className="reply-image-icon" />
        )}
        <span className="reply-text">{replyTo.text}</span>
      </div>
    </div>
  );
};

const GroupChatInterface = ({ group: initialGroup, onBack }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [group, setGroup] = useState(initialGroup);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [error, setError] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [showSendButton, setShowSendButton] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [botResponsesEnabled, setBotResponsesEnabled] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [showAllPosts, setShowAllPosts] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [joinedAt, setJoinedAt] = useState(null);
  const [users, setUsers] = useState({});
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const topOfListRef = useRef(null);
  const textareaRef = useRef(null);
  const messageListRef = useRef(null);
  const functions = getFunctions();
  const difyGroupChat = httpsCallable(functions, 'difyGroupChatWorkflow');

  // Helper function to check if a link is internal
  const isInternalLink = (href) => {
    try {
      const baseURL = window.location.origin;
      const linkUrl = new URL(href, baseURL);
      return linkUrl.origin === baseURL;
    } catch (error) {
      // If parsing fails, treat it as internal
      return true;
    }
  };

  // Helper function to extract the internal path
  const getInternalPath = (href) => {
    try {
      const baseURL = window.location.origin;
      const linkUrl = new URL(href, baseURL);
      return linkUrl.pathname + linkUrl.search + linkUrl.hash;
    } catch (error) {
      // If parsing fails, return the href as is
      return href;
    }
  };

  // Define the custom link renderer
  const CustomLinkRenderer = ({ href, children, ...props }) => {
    const isInternal = isInternalLink(href);

    if (isInternal) {
      const internalPath = getInternalPath(href);
      return (
        <Link to={internalPath} {...props}>
          {children}
        </Link>
      );
    } else {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
          {children}
        </a>
      );
    }
  };

  // All Posts handlers
  const handleShowAllPosts = () => {
    setShowAllPosts(true);
  };

  const handleHideAllPosts = () => {
    setShowAllPosts(false);
  };

  // Check if current user is admin and fetch joinedAt
  useEffect(() => {
    if (!group) return;
  
    const memberRef = doc(db, `groups/${group.id}/members/${auth.currentUser.uid}`);
    const unsubscribe = onSnapshot(
      memberRef,
      (doc) => {
        if (doc.exists()) {
          const memberData = doc.data();
          setIsAdmin(memberData.role === 'admin');
          setJoinedAt(memberData.joinedAt ? memberData.joinedAt.toDate() : null);
          setNotificationsEnabled(memberData.notificationsEnabled !== false);
          setBotResponsesEnabled(memberData.botResponsesEnabled !== false);
          setError(null);
        } else {
          setError('You are not a member of this group.');
        }
      },
      (error) => {
        console.error('Error fetching member data:', error);
        setError('Failed to fetch membership data.');
      }
    );
  
    return () => unsubscribe();
  }, [group]);

  // Function to fetch a single user's data
  const fetchUserData = useCallback(async (userId) => {
    if (users[userId]) return; // User data already fetched

    try {
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setUsers((prevUsers) => ({
          ...prevUsers,
          [userId]: {
            displayName: userData.displayName,
            profilePictureUrl: userData.profilePictureUrl,
          },
        }));
      }
    } catch (error) {
      console.error(`Error fetching user data for ${userId}:`, error);
    }
  }, [users]);

  // Function to fetch multiple users' data
  const fetchUsersData = useCallback(async (userIds) => {
    const uniqueUserIds = [...new Set(userIds)].filter((id) => !users[id]);
    await Promise.all(uniqueUserIds.map((id) => fetchUserData(id)));
  }, [fetchUserData, users]);

  // Subscribe to group updates
  useEffect(() => {
    if (!group?.id) return;

    const groupRef = doc(db, 'groups', group.id);
    const unsubscribe = onSnapshot(groupRef, (doc) => {
      if (doc.exists()) {
        setGroup({
          id: doc.id,
          ...doc.data()
        });
      }
    });

    return () => unsubscribe();
  }, [group?.id]);

  // Subscribe to messages with joinedAt constraint
  useEffect(() => {
    if (!group?.id || !joinedAt) return; // Ensure joinedAt is available

    const messagesRef = collection(db, `groups/${group.id}/messages`);
    const q = query(
      messagesRef,
      where('timestamp', '>=', joinedAt),
      orderBy('timestamp', 'desc'),
      limit(50)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = [];
      const senderIds = new Set();

      querySnapshot.forEach((doc) => {
        const messageData = { id: doc.id, ...doc.data() };
        fetchedMessages.push(messageData);
        senderIds.add(messageData.senderId);
      });

      setMessages(fetchedMessages.reverse());
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMoreMessages(querySnapshot.docs.length === 50);

      // Fetch user data for senders
      fetchUsersData(Array.from(senderIds));

      // Set initial load as complete after first batch of messages
      if (!initialLoadComplete) {
        setInitialLoadComplete(true);
      }
    });

    return () => unsubscribe();
  }, [group?.id, joinedAt, initialLoadComplete, fetchUsersData]);

  // Add new effect for initial scroll
  useEffect(() => {
    if (initialLoadComplete && messageListRef.current && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [initialLoadComplete]);

  // Function to load more messages with joinedAt constraint
  const loadMoreMessages = useCallback(async () => {
    if (!group?.id || !lastVisible || isLoadingMore || !hasMoreMessages || !joinedAt) return;

    setIsLoadingMore(true);

    try {
      const messagesRef = collection(db, `groups/${group.id}/messages`);
      const q = query(
        messagesRef,
        where('timestamp', '>=', joinedAt),
        orderBy('timestamp', 'desc'),
        startAfter(lastVisible),
        limit(50)
      );

      const querySnapshot = await getDocs(q);
      const fetchedMessages = [];
      const senderIds = new Set();

      querySnapshot.forEach((doc) => {
        const messageData = { id: doc.id, ...doc.data() };
        fetchedMessages.push(messageData);
        senderIds.add(messageData.senderId);
      });

      if (fetchedMessages.length > 0) {
        const messageList = messageListRef.current;
        const previousScrollHeight = messageList.scrollHeight;

        setMessages((prevMessages) => [
          ...fetchedMessages.reverse(),
          ...prevMessages,
        ]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMoreMessages(fetchedMessages.length === 50);

        // Fetch user data for new senders
        fetchUsersData(Array.from(senderIds));

        // After messages are prepended, adjust scrollTop to maintain position
        requestAnimationFrame(() => {
          const newScrollHeight = messageList.scrollHeight;
          messageList.scrollTop = newScrollHeight - previousScrollHeight + messageList.scrollTop;
        });
      } else {
        setHasMoreMessages(false);
      }
    } catch (error) {
      console.error('Error loading more messages:', error);
      setError(t('chat.load_more_error')); // Update error state
    } finally {
      setIsLoadingMore(false);
    }
  }, [group?.id, lastVisible, isLoadingMore, hasMoreMessages, joinedAt, fetchUsersData, t]);

  // Set up IntersectionObserver to load more messages when scrolled to top
  useEffect(() => {
    if (!topOfListRef.current || !hasMoreMessages) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLoadingMore) {
            loadMoreMessages();
          }
        });
      },
      { root: null, rootMargin: '200px', threshold: 0.1 }
    );

    observer.observe(topOfListRef.current);

    return () => {
      observer.disconnect();
    };
  }, [loadMoreMessages, isLoadingMore, hasMoreMessages]);

  useEffect(() => {
    if (!initialLoadComplete) return; // Skip during initial load

    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 300;
  
      if (isNearBottom) {
        // Use requestAnimationFrame for smoother scrolling
        requestAnimationFrame(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        });
      }
    }
  }, [messages.length, initialLoadComplete]);

  // Update lastReadAt and unreadCount when component mounts and when user interacts
  useEffect(() => {
    const updateLastReadAt = async () => {
      if (group && auth.currentUser) {
        try {
          const memberRef = doc(db, `groups/${group.id}/members/${auth.currentUser.uid}`);
          await updateDoc(memberRef, {
            lastReadAt: serverTimestamp(),
            unreadCount: 0,
          });
        } catch (error) {
          console.error('Error updating lastReadAt and unreadCount:', error);
        }
      }
    };
  
    updateLastReadAt();

    // Update lastReadAt when user focuses the input
    const handleFocus = () => {
      updateLastReadAt();
    };

    // Capture the textarea element
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.addEventListener('focus', handleFocus);
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('focus', handleFocus);
      }
    };
  }, [group]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '48px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  const handleImageUpload = (imagesData) => {
    setUploadedImages((prevImages) => [...prevImages, ...imagesData]);
  };

  const handleRemoveImage = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleImageIconClick = () => {
    fileInputRef.current.click();
  };

  const handleOpenSettings = () => {
    setShowSettings(true);
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const handleUpdateGroup = (updatedGroup) => {
    setGroup(updatedGroup);
  };

  const handleLeaveGroup = () => {
    navigate('/groups');
  };

  const handleToggleNotifications = async () => {
    try {
      const memberRef = doc(db, `groups/${group.id}/members/${auth.currentUser.uid}`);
      await updateDoc(memberRef, {
        notificationsEnabled: !notificationsEnabled,
      });
      setNotificationsEnabled(!notificationsEnabled);
    } catch (error) {
      console.error('Error toggling notifications:', error);
      setError(t('groups.notification_toggle_error'));
    }
  }; 

  const handleToggleBotResponses = async () => {
    try {
      const memberRef = doc(db, `groups/${group.id}/members/${auth.currentUser.uid}`);
      await updateDoc(memberRef, {
        botResponsesEnabled: !botResponsesEnabled,
      });
      setBotResponsesEnabled(!botResponsesEnabled);
    } catch (error) {
      console.error('Error toggling bot responses:', error);
      setError(t('groups.bot_toggle_error'));
    }
  };

  const handleImageLoad = useCallback(() => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 300;

      if (isNearBottom) {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, []);

  const sendMessage = async (e) => {
    e.preventDefault();
    if ((input.trim() === '' && uploadedImages.length === 0) || !group) return;
  
    const user = auth.currentUser;
    if (!user) return;
  
    setIsLoading(true);
    setError(null);
  
    try {
      const images = uploadedImages.map((image) => ({
        url: image.url,
        name: image.name,
        type: image.type,
      }));

      // Get user location
      const location = await getLocation();
  
      const messageData = {
        text: input,
        senderId: user.uid,
        timestamp: serverTimestamp(),
        images: images.length > 0 ? images : null,
        type: 'user',
        location: location ? new GeoPoint(location.lat, location.lng) : null,
      };
  
      // Add the message to the messages sub-collection
      const messagesRef = collection(db, `groups/${group.id}/messages`);
      const newMessageRef = await addDoc(messagesRef, messageData);
  
      // Update the group document with last message info
      const groupRef = doc(db, 'groups', group.id);
      await updateDoc(groupRef, {
        lastMessage: input || (images.length > 0 ? 'Image' : ''),
        lastMessageAt: serverTimestamp(),
        lastMessageSenderId: user.uid,
        lastMessageSenderName: user.displayName || 'Unknown',
        lastActivityAt: serverTimestamp(),
      });

      // Reset input and uploaded images
      setInput('');
      setUploadedImages([]);
      setShowSendButton(false);

      // Set isLoading to false to re-enable the send button
      setIsLoading(false);

      // Only call difyGroupChat if bot responses are enabled
      if (botResponsesEnabled) {
        // Call difyGroupChat function
        difyGroupChat({
          query: input,
          groupId: group.id,
          images: images,
          messageId: newMessageRef.id, // Pass the message ID for reference
        }).catch((error) => {
          console.error('Error in difyGroupChat:', error);
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setError(t('chat.send_error'));
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInput(inputValue);
    setShowSendButton(inputValue.trim().length > 0 || uploadedImages.length > 0);
  };

  // Show skeleton loading state
  if (!group || !joinedAt || !initialLoadComplete) {
    return <SkeletonGroupChat />;
  }

  return (
    <div className="group-chat-interface">
      <GroupHeader
        group={group}
        onBack={onBack}
        onOpenSettings={handleOpenSettings}
        onToggleBotResponses={handleToggleBotResponses}
        botResponsesEnabled={botResponsesEnabled}
        onShowAllPosts={handleShowAllPosts}
      />

      <div className="message-list" ref={messageListRef}>
        <div ref={topOfListRef} />
        {isLoadingMore && (
          <div className="loading-more">
            <div className="spinner" />
            <span>{t('chat.loading_more')}</span>
          </div>
        )}
        <div className={`scroll-indicator ${hasMoreMessages && !isLoadingMore ? 'visible' : ''}`}>
          {t('chat.scroll_to_load')}
        </div>
        {messages.map((message, index) => {
          const isCurrentUser = message.senderId === auth.currentUser?.uid;
          const isBot = message.senderId === 'bot';
          const sender = users[message.senderId] || {};

          // Determine if we should show a date separator
          const showDateSeparator = index === 0 || 
            (message.timestamp && messages[index - 1].timestamp && 
             !isToday(message.timestamp.toDate()) === isToday(messages[index - 1].timestamp.toDate()));
          
          // Determine if the current message is the first in a consecutive series
          const isFirstInSequence = index === 0 || messages[index - 1].senderId !== message.senderId;

          return (
            <React.Fragment key={message.id}>
              {showDateSeparator && message.timestamp && (
                <DateSeparator timestamp={message.timestamp} t={t} />
              )}
              <div
                key={message.id}
                className={`message-container ${isCurrentUser ? 'sent' : 'received'} ${isBot ? 'bot' : ''}`}
              >
                {/* Render avatar and display name for non-current users and non-bot messages */}
                {!isCurrentUser && !isBot && isFirstInSequence && (
                  <>
                    <div className="avatar-container">
                      <Link to={`/profile/${message.senderId}`} aria-label={`View ${sender.displayName}'s profile`}>
                        {sender.profilePictureUrl ? (
                          <img
                            src={sender.profilePictureUrl}
                            alt={sender.displayName || t('chat.user')}
                            className="sender-profile-picture"
                          />
                        ) : (
                          <DefaultAvatar
                            name={sender.displayName}
                            size={32}
                            fallbackColor="var(--color-primary-light)"
                            textColor="var(--color-surface)"
                            className="sender-profile-picture"
                          />
                        )}
                      </Link>
                    </div>
                    <div className="sender-display-name">
                      {sender.displayName || t('chat.user')}
                    </div>
                  </>
                )}

                {/* Render avatar and display name for bot messages */}
                {!isCurrentUser && isBot && isFirstInSequence && (
                  <>
                    <div className="avatar-container">
                      <Link to="/" aria-label={t('chat.view_home')}>
                        <img
                          src={botIcon}
                          alt={t('chat.affin_icon')}
                          className="sender-profile-picture"
                        />
                      </Link>
                    </div>
                    <div className="sender-display-name">
                      {t('app_name')}
                    </div>
                  </>
                )}

                {/* Conditionally render the message content */}
                {(message.type === 'user' || message.type === 'bot') && (
                  <div
                    id={`message-${message.id}`} 
                    className={`message ${isCurrentUser ? 'sent' : 'received'}`}
                  >
                    {message.inReplyTo && (
                      <MessageReply messageId={message.inReplyTo} messages={messages} />
                    )}
                    <div className="message-content">
                      {message.images && message.images.map((image, idx) => (
                        <img
                          key={idx}
                          src={image.url}
                          alt={image.name || t('chat.uploaded_image', { index: idx + 1 })}
                          className="message-image"
                          onLoad={handleImageLoad}
                        />
                      ))}
                      <ReactMarkdown
                        components={{ a: CustomLinkRenderer }}
                        remarkPlugins={[remarkGfm, remarkBreaks]}
                      >
                        {message.text}
                      </ReactMarkdown>
                    </div>
                    {message.timestamp && (
                      <MessageTimestamp timestamp={message.timestamp} />
                    )}
                  </div>
                )}

                {/* Render GroupChatPostList outside the message div */}
                {isBot && message.type === 'search_results' && message.postIds && (
                  <GroupChatPostList
                    postIds={message.postIds}
                  />
                )}

                {/* Render GroupDraftItem outside the message div */}
                {isBot && message.type === 'draft_post' && message.draftId && (
                  <DraftItem
                    draftId={message.draftId}
                    groupId={group.id}
                  />
                )}

                {/* Render GroupChatPostItemWrapper outside the message div */}
                {message.type === 'post' && message.postId && (
                  <PostMessage postId={message.postId} />
                )}
              </div>
            </React.Fragment>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="message-input-container">
        {uploadedImages.length > 0 && (
          <div className="uploaded-images-preview">
            {uploadedImages.map((image, index) => (
              <div key={index} className="uploaded-image-container">
                <img 
                  src={image.url} 
                  alt={t('chat.uploaded_image', { index: index + 1 })} 
                  className="uploaded-image" 
                />
                <button
                  onClick={() => handleRemoveImage(index)}
                  className="remove-image-button"
                  aria-label={t('chat.remove_image')}
                >
                  <X size={20} />
                </button>
              </div>
            ))}
          </div>
        )}

        <form onSubmit={sendMessage} className="message-input">
          <button
            type="button"
            onClick={handleImageIconClick}
            className="image-upload-button"
            aria-label={t('chat.upload_images')}
            disabled={uploadedImages.length >= MAX_IMAGES || isLoading}
            title={uploadedImages.length >= MAX_IMAGES 
              ? t('chat.max_images_reached', { max: MAX_IMAGES }) 
              : t('chat.upload_images')}
          >
            <Image size={22} />
          </button>
          
          <textarea
            ref={textareaRef}
            value={input}
            onChange={handleInputChange}
            placeholder={t('chat.type_message')}
            disabled={isLoading}
            rows={1}
          />
          
          <button
            type="submit"
            disabled={isLoading || (input.trim().length === 0 && uploadedImages.length === 0)}
            className={`send-button ${showSendButton ? 'visible' : ''} ${
              isLoading ? 'loading' : ''
            }`}
            aria-label={isLoading ? t('chat.processing_message') : t('chat.send_message')}
          >
            {isLoading ? <Loader size={20} className="loader" /> : <Send size={20} />}
          </button>
        </form>
      </div>

      <ImageUploader
        onImageUpload={handleImageUpload}
        fileInputRef={fileInputRef}
        currentImageCount={uploadedImages.length}
        maxImages={MAX_IMAGES}
        groupId={group?.id || null}
      />

      {showSettings && (
        <GroupSettings
          group={group}
          onClose={handleCloseSettings}
          onUpdate={handleUpdateGroup}
          onLeaveGroup={handleLeaveGroup}
          notificationsEnabled={notificationsEnabled}
          onToggleNotifications={handleToggleNotifications}
          isAdmin={isAdmin}
        />
      )}

      {showAllPosts && (
        <AllPostsInGroup 
          groupId={group.id} 
          onClose={handleHideAllPosts}
        />
      )}
    </div>
  );
};

export default GroupChatInterface;
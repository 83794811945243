// src/components/BottomNavigation/BottomNavigation.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Bot, UsersRound, CircleUserRound } from 'lucide-react';
import './BottomNavigation.css';

const BottomNavigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [showIndicator, setShowIndicator] = useState(false);
  
  useEffect(() => {
    setShowIndicator(true);
  }, [location.pathname]);

  const isActive = (path) => location.pathname === path;

  const handleNavigate = () => {
    setShowIndicator(false);
  };

  return (
    <nav className="bottom-navigation">
      <div className="bottom-navigation-container">
        <Link 
          to="/" 
          className={isActive('/') ? 'active' : ''} 
          aria-label={t('navigation.home')}
          onClick={handleNavigate}
        >
          <div className="icon-container">
            <Bot size={24} />
            {isActive('/') && showIndicator && <div className="indicator" />}
          </div>
        </Link>

        <Link 
          to="/friends" 
          className={isActive('/friends') ? 'active' : ''} 
          aria-label={t('navigation.friends')}
          onClick={handleNavigate}
        >
          <div className="icon-container">
            <UsersRound size={24} />
            {isActive('/friends') && showIndicator && <div className="indicator" />}
          </div>
        </Link>

        <Link 
          to="/profile" 
          className={isActive('/profile') ? 'active' : ''} 
          aria-label={t('navigation.profile')}
          onClick={handleNavigate}
        >
          <div className="icon-container">
            <CircleUserRound size={24} />
            {isActive('/profile') && showIndicator && <div className="indicator" />}
          </div>
        </Link>
      </div>
    </nav>
  );
};

export default BottomNavigation;